import { css } from '@emotion/react'
import React from 'react'

import Single from '../components/contents/Single'
import { mq, rem } from '../styles/mixin.styles'
import { fontSize, space } from '../styles/variables.styles'

type Props = {
  location: {
    [key: string]: string
  }
}

const PrivacyPage = ({ location }: Props) => {
  return (
    <Single slug="privacy" pathname={location.pathname}>
      <div css={privacyStyles}>
        <p>
          五十鈴関東株式会社（以下、「当社」といいます。）は、当社が運営するパレット調達支援システムiPaS及びこれに関連するすべてのサービス（以下、「本件サービス」といいます。）の提供を行うに当たって、個人の人格を尊重し、個人情報の保護に関する法令等を遵守して個人情報を適切に取り扱います。
          本個人情報保護方針は、本件サービスにおいて、個人情報もしくはそれに準ずる情報を取り扱う際に、当社が遵守する方針を示したものです。
        </p>

        <h2>第1条（個人情報保護方針に関する宣言）</h2>
        <ol>
          <li>個人情報の保護に関する責任者を明確にし、社内規程を制定することにより管理体制を整備します。</li>
          <li>
            個人情報は、あらかじめ本人に通知し、または公表した利用目的の達成に必要な範囲内で、適正かつ適切に取得し取り扱います。
          </li>
          <li>個人情報の紛失、破壊、改ざん、漏洩等を防止するため、適切な安全管理措置を講じます。</li>
          <li>個人情報を第三者に提供する際には、法令に従い必要な措置を講じます。</li>
          <li>個人情報の開示、訂正、利用停止等のご要望及びお問い合わせに対しては、できる限り速やかに対応します。</li>
          <li>
            社内教育・啓発活動を実施して個人情報の適正な取扱いについて周知徹底に努めるとともに、管理体制を継続的に見直します。
          </li>
        </ol>
        <h2>第2条（個人情報の利用目的について）</h2>
        <ol>
          <li>
            当社は、他の方法でお知らせする場合を除き、ご提供いただいた個人情報を以下に定める目的の達成に必要な範囲で取り扱います。
            <ol className="ol--brackets">
              <li>
                本件サービスの利用者（以下、「お客さま」といいます。）から取得した情報
                <ol>
                  <li>お客さまと当社との間における契約の締結・履行、その他の取引管理</li>
                  <li>お客さまに対する本件サービスの提供及び付随業務の提供</li>
                  <li>お客さまと当社との連絡及び社会的慣習に基づくご通知・ご挨拶</li>
                  <li>当社の商品・サービスに関する情報のご提供、その他マーケティング活動・アンケート等の実施</li>
                  <li>当社事業に係わる調査・分析・研究・監査</li>
                  <li>
                    当社事業に係わる業界・団体関係者との情報交換・連絡（当社事業の詳細に関しては、五十鈴グループホームページ「会社概要」をご確認下さい。）
                  </li>
                  <li>お客様からのお問合せへの対応のため</li>
                </ol>
              </li>
              <li>
                お客さま以外の取引先等、委託先等から取得した情報
                <ol>
                  <li>取引又は委託に関する検討、当該契約等の目的を達成するために必要な範囲での管理、連絡等</li>
                  <li>当社の商品・サービスに関する情報のご提供、その他マーケティング活動の実施</li>
                </ol>
              </li>
              <li>
                イベント、カンファレンス、セミナー等（以下、「イベント等」といいます。）への参加者、アンケート等への回答者の個人情報
                <ol>
                  <li>当社を含む五十鈴グループ各社が実施するイベント等の案内・運営・実施</li>
                  <li>新たなイベント等又は商品・サービスの企画・開発</li>
                  <li>当社の商品・サービスに関する情報のご提供、その他マーケティング活動の実施</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            当社では、前項に定める利用目的を達成するため、提供いただいた個人情報を業務委託先に委託する場合がございます。また、法令等により提供を求められた場合には、当該公的機関に提供することがございます。
          </li>
        </ol>
        <h2>第3条（個人情報の第三者への提供）</h2>
        <p>
          当社は、個人情報を第三者へ提供する場合には、提供先・提供内容を特定し、ご本人の事前の同意を得ます。ただし、以下の場合はこの限りではありません。
        </p>
        <ol className="ol--brackets">
          <li>法令に基づく場合</li>
          <li>人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
          <li>
            公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき
          </li>
          <li>
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
          </li>
          <li>
            当該第三者が学術研究機関等である場合であって、当該第三者が当該個人データを学術研究目的で取り扱う必要があるとき（当該個人データを取り扱う目的の一部が学術研究目的である場合を含み、個人の権利利益を不当に侵害するおそれがある場合を除きます。）
          </li>
        </ol>
        <h2>第4条（委託先の監督）</h2>
        <p>
          個人情報の取扱いの全部又は一部を委託する場合、当社は、委託先と機密保持を含む契約を締結し、又は委託先に対し当社が定める約款への合意を求め、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
        </p>
        <h2>第5条（個人情報の共同利用について）</h2>
        <p>当社は以下に定める通り、個人情報を共同利用する場合がございます。</p>
        <ol className="ol--brackets">
          <li>
            共同利用される個人情報
            <br />
            第2条に定める個人情報
          </li>
          <li>
            共同利用する範囲
            <br />
            五十鈴グループ各社
          </li>
          <li>
            共同利用の目的
            <br />
            第2条に定める利用目的と同一の目的（ただし、本号においては、「当社事業」を「五十鈴グループが行う事業」に読み替えるものとします。）
          </li>
          <li>
            共同利用する個人情報の管理責任者
            <br />
            〒323-0819
            <br />
            栃木県小山市大字横倉新田470番地4
            <br />
            五十鈴関東株式会社
            <br />
            （代表取締役 本郷正信）
          </li>
        </ol>
        <h2>第6条（要配慮個人情報の取扱い）</h2>
        <p>
          当社は、要配慮個人情報（ご本人の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪による害を被った事実その他ご本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱いに特に配慮を要するものとして政令で定める記述等が含まれる個人情報をいいます。）を取得する場合及び第三者に提供する場合には、法令に定められた例外的な場合を除き、あらかじめご本人の同意を得るものとします。
        </p>
        <h2>第7条（個人情報に関するお問い合わせ）</h2>
        <p>
          当社では「個人情報の保護に関する法律」に基づき、当社が保有している皆さまの個人情報に関する利用目的の通知、開示、訂正等、利用停止等のお申込みを受け付けております。
          <br />
          お申込みを希望される方は、以下の各項をお読みの上、後掲のお問い合わせ窓口宛に郵送にてご請求ください。
          <br />
          なお、個人情報に関するご請求、お申込み以外の各種サービスに関するお問い合わせやご登録情報の変更、退会手続き等をご希望の方は、当社各種サービスの連絡先までご連絡ください。
        </p>
        <ol>
          <li>
            利用目的の通知
            <br />
            当社がお客さまよりいただいた個人情報の利用目的の通知の請求をされる方は「保有個人データ利用目的通知請求書PDF（12KB）」をプリントアウトし、必要事項をご記入の上、後掲の送付先へご送付ください。
          </li>
          <li>
            開示
            <br />
            当社がお客さまよりいただいた個人情報の開示請求をされる方は「保有個人データ開示請求書PDF（16KB）」をプリントアウトし、必要事項をご記入の上、後掲の送付先へ本人確認書類の写しを添えてご送付ください。
          </li>
          <li>
            訂正等
            <br />
            当社がお客さまよりいただいた全ての個人情報の項目について、訂正・追加・削除のお申込みをされる方は「保有個人データ訂正等申込書PDF（16KB）」をプリントアウトし、必要事項をご記入の上、後掲の送付先へ本人確認書類の写しを添えてご送付ください。
          </li>
          <li>
            利用の停止等
            <br />
            当社がお客さまよりいただいた個人情報の利用停止・消去又は第三者提供の停止のお申込みをされる方は「保有個人データ利用停止等申込書PDF（16KB）」をプリントアウトし、必要事項をご記入の上、後掲の送付先へ本人確認書類の写しを添えてご送付ください。
            <br />
            なお、当社へ送付される際には、紛失等のおそれがあるため、書留等の相当な手段によりご送付ください（なお、ご送付に伴う送料は、ご請求される方の負担とさせていただきます）。当サイトより、書式をプリントアウトできる環境にない方には、当該書式を送付いたしますので、後掲の送付先まで書面にてお申込みください。
          </li>
          <li>
            本人確認用書類について
            <br />
            各種ご請求に際し、当社は、請求された方が請求の対象である個人情報に係るお客さまご本人あるいはその正当な代理人であることを確認するため、当該確認に必要な書類等の提示を求めることができるものとします。ご本人さまかどうかを確認させていただく書類として、次のうちいずれかの写しを、上述の書類に添付してください。但し、当社内、不特定多数の部署に対するご請求をされる場合には、別途当社よりご案内する書式へのご記入をお願いする場合がございます。
            <ol className="ol--brackets">
              <li>運転免許証：有効期限内のもので、現住所が記載されている面の写しを含むこと。国際運転免許証は除く。</li>
              <li>日本国の旅券（パスポート）：有効期限内のもので、現住所が記入されている面の写しを含むこと</li>
              <li>
                健康保険証あるいは年金手帳＋次のいずれか（住民票、公共料金領収書、公共料金請求書）：住民票・公共料金領収書・公共料金請求書は、発行日より3ヵ月以内で、現住所が記載されているもの。
              </li>
            </ol>
          </li>
          <li>
            代理人の方によるご請求の場合の書類について
            <br />
            代理人の方による各種ご請求の場合、上述の書類に加え、以下の書類を全てご送付下さい。
            <ol className="ol--brackets">
              <li>
                任意代理人の場合
                <ol>
                  <li>
                    ご本人さまによる委任状（代理人との関係、代理を要する理由、代理人の方の氏名・ご住所・電話番号も記載）
                  </li>
                  <li>ご本人さまの印鑑証明書（委任状には、印鑑証明登録印の押印が必要となります）</li>
                  <li>代理人の方の身分証明書（運転免許証等公的書類の写し）</li>
                </ol>
              </li>
              <li>
                法定代理人（親権者・成年後見人）の場合
                <ol>
                  <li>法定代理権があることを確認するための書類（保険証等の写し） </li>
                  <li>
                    法定代理人本人であることを確認するための書類（法定代理人の運転免許証等公的書類の写し）
                    <br />
                    ※本人確認用書類として、運転免許証や住民票等を利用される場合は、当該「本籍地」を黒で塗りつぶすなどして読み取りできない状態にしてご送付ください。
                    本人確認のためにご提出いただく書類は、当該ご請求に対するご本人さまの確認及びご請求内容に関するご連絡のみに使用いたします。また書類の返却はいたしませんが、十分な安全策に則り厳重に廃棄処分いたします。
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            ご連絡の方法
            <br />
            ご請求に関する通知、開示につきましては、郵送による通知といたします。ご希望に添えないことが判明した場合には、その旨に理由を添えて、ご指定の希望送付先住所宛に、郵送にて連絡をいたします。
          </li>
          <li>
            対応にかかる期間
            <br />
            ご請求を受領してから以下の期間内に対応を完了できるよう努力いたします。
            <ol className="ol--brackets">
              <li>
                利用目的の通知
                <br />
                特定部署へのご依頼…受領日より5営業日
                <br />
                不特定あるいは複数部署へのご依頼…受領日より3週間
              </li>
              <li>
                開示、訂正等、利用の停止等
                <br />
                特定部署へのご依頼…ご本人さまの確認が取れた時点より5営業日
                <br />
                不特定あるいは複数部署へのご依頼…ご本人さまの確認が取れた時点より3週間を目安に回答・通知を発送いたします。
              </li>
            </ol>
          </li>
          <li>
            手数料
            <br />
            以下ご請求の場合、1回につき書留送料430円分の切手を申請書類に同封してください。
            <ol className="ol--brackets">
              <li>利用目的の通知</li>
              <li>
                情報の開示
                <br />
                手数料が不足している場合、及び手数料が同封されていなかった場合は、その旨ご連絡いたしますが、所定の期間内にお支払いのない場合は、ご請求がなかったものとして処理いたします。あらかじめご了承ください。
              </li>
            </ol>
          </li>
          <li>
            ご請求に応じられない事由について
            <br />
            次に定める場合には、ご請求に応じられないことがあります。その場合は、その旨、理由を付記して通知申し上げます。また、ご請求に応じられない場合についても所定の手数料をいただきます。
            請求書・申込書に記載されている住所、本人確認のための書類に記載されている住所、当社の登録住所が一致しないときなど本人が確認できない場合
            <ol className="ol--brackets">
              <li>代理人による申請に際して、代理権が確認できない場合</li>
              <li>所定の請求書・申込書に不備があった場合</li>
              <li>ご請求並びにお申込みの対象が「保有個人データ」に該当しない場合</li>
              <li>本人又は第三者の生命、身体、財産その他の権利利益を害する恐れがある場合</li>
              <li>当社の業務の適正な実施に著しい支障を及ぼす恐れがある場合</li>
              <li>他の法令に違反することとなる場合</li>
            </ol>
          </li>
          <li>
            送付先及び保有個人データの取扱いに関する苦情の申し出先
            <br />
            〒323-0819 栃木県小山市大字横倉新田470-4
            <br />
            五十鈴関東株式会社 個人情報お問い合わせ窓口 行<br />
            E-mail：asaoka@isz.co.jp
            <br />
            なお、直接ご来社いただいてのご請求はお受けかねますので、その旨ご了承くださいますようお願い申し上げます。
          </li>
        </ol>
        <h2>第8条（個人情報保護方針の変更について）</h2>
        <p>
          当社では、取得する個人情報の変更、利用目的の変更、またはその他個人情報保護方針の変更を行う際は、当ページの変更をもって公表とさせていただきます。変更後の個人情報保護方針はサイト上に改定日を表示した時点より効力を生じます。
        </p>

        <footer>
          <address>
            〒323-0819
            <br />
            栃木県小山市大字横倉新田470番地4
            <br />
            五十鈴関東株式会社
            <br />
            代表取締役 本郷正信
          </address>
          <p>
            <time dateTime="2022-06-23">2022年6月23日</time>制定・施行
          </p>
        </footer>
      </div>
    </Single>
  )
}

export default PrivacyPage

export const privacyStyles = css`
  font-size: ${fontSize.sm};
  width: min(calc(100% - 1.25rem * 2), 62.5rem);
  margin: auto;
  h2 {
    font-size: ${fontSize.md};
    margin-top: ${space.sm};
    + * {
      margin-top: calc(${space.sm} / 2);
    }
  }
  p,
  li {
    line-height: 1.7;
  }
  p {
    + ol,
    + ul,
    + p {
      margin-top: ${rem(10)};
    }
  }
  li {
    + li {
      margin-top: ${space.xs};
      > ol,
      ol {
        margin-top: ${rem(8)};
      }
    }
  }
  ol {
    margin-left: 1.3em;
  }
  footer {
    margin-top: clamp(${space.md}, 3vw, calc(${space.md} * 2));
    time {
      padding-right: 1em;
    }
    address {
      font-style: normal;
      + p {
        margin-top: ${space.md};
      }
    }
  }
  ${mq('lg')} {
    address {
      text-align: right;
      + p {
        margin-top: ${rem(18)};
      }
    }
  }
`
